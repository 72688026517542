/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$("#slider").slick({
  dots: true,
  customPaging: function(slider, i) { 
      var id = $(slider.$slides[i]).find('.slide').attr('product');
      return '<button class="tab">' + id + '</button>';
  },
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendDots: $('#slider-menu')
});